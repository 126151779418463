// Dependency
import React from "react"
import { graphql } from "gatsby"
import { useStoryblokState } from "gatsby-source-storyblok"
import DynamicComponent from "../helpers/storyblok/dynamicComponent"
import { checkBackgroundColor } from "../helpers/checkBackgroundColor"

const Listing = ({ data, pageContext, location }) => {
  let story = data.storyblokEntry
  story = useStoryblokState(story)
  const bgColor = checkBackgroundColor(
    pageContext?.variables?.variable?.product?.backgroundColor
  )

  const components = story.content.sections.map(blok => {
    blok["bgColor"] = bgColor
    blok["location"] = location
    return <DynamicComponent blok={blok} key={blok._uid} />
  })

  return <div className="listing-page">{components}</div>
}

export default Listing

export { Head } from "./page"

export const query = graphql`
  query ListingQuery($full_slug: String!) {
    storyblokEntry(full_slug: { eq: $full_slug }) {
      ...StoryEntry
    }
  }
`
